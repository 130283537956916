<template>
  <b-modal
    id="line-oa-user"
    :visible="shallShowLineOaUserModal"
    :title="`${$t('User')} ${$t('LINE OA')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-line-oa-user-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t('User')}  ${$t('LINE OA')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-line-oa-user-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        ref="refForm"
        class="position-relative"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-card no-body>
          <b-row
            align-v="center"
          >
            <b-col
              cols="5"
            >
              <b-form-group
                :label="`${$t('User')}`"
                label-for="line-oa-user-inactive"
                class="ml-2 mt-1"
              >
                <b-overlay
                  :show="selectedBusyFlag"
                  opacity="0.6"
                >
                  <b-form-select
                    v-model="inActiveSelected"
                    :options="inActiveOptions"
                    multiple
                    :select-size="20"
                  />
                </b-overlay>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-row>
                <b-col align="center">
                  <b-button
                    variant="link"
                    class="button-pattern-throw"
                    @click="
                      moveOptionsToSelect('LTR')
                    "
                  >
                    <feather-icon
                      size="24"
                      icon="ArrowRightCircleIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col align="center">
                  <b-button
                    variant="link"
                    class="button-pattern-throw"
                    @click="
                      moveOptionsToSelect('RTL')
                    "
                  >
                    <feather-icon
                      size="24"
                      icon="ArrowLeftCircleIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="5">
              <b-form-group
                :label="`${$t('Selected')}`"
                label-for="line-oa-user-inactive"
                class="mr-2 mt-1"
              >
                <b-overlay
                  :show="selectedBusyFlag"
                  opacity="0.6"
                >
                  <b-form-select
                    v-model="activeSelected"
                    :options="activeOptions"
                    multiple
                    :select-size="20"
                  />
                </b-overlay>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormSelect,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BOverlay,
    BCard,
    BProgress,
    BCardText,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowLineOaUserModal',
    event: 'update:shall-show-line-oa-user-modal',
  },
  props: {
    shallShowLineOaUserModal: {
      type: Boolean,
      required: true,
    },
    lineOaUserData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      selectedBusyFlag: false,
      inActiveSelected: [],
      inActiveOptions: [],
      activeSelected: [],
      activeOptions: [],
    }
  },
  watch: {
    lineOaUserData(data) {
      this.inActiveOptions = []
      if (data.user_inactive) {
        data.user_inactive.forEach(item => {
          this.inActiveOptions.push({
            text: `${item.name} (${item.email})`,
            value: item.id,
          })
        })
      }
      this.activeOptions = []
      if (data.user_active) {
        data.user_active.forEach(item => {
          this.activeOptions.push({
            text: `${item.name} (${item.email})`,
            value: item.id,
          })
        })
      }
    },
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      store.dispatch('store-line-oa/changeLineOaUser',
        {
          lineOaId: this.lineOaUserData.line_oa_id,
          data: this.activeOptions,
        })
        .then(response => {
          if (response.status === 200) {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('line-oa-user-update')
            this.$emit('update:shall-show-line-oa-user-modal', false)
          } else {
            const errorMsg = this.$i18n.t('Process on error')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (response.status === 409) {
              errorMsg = `${this.$i18n.t(response?.data.error)}, '${this.$i18n.t('Channel ID')}' ${this.$i18n.t('Duplicate')}`
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    onDiscard() {
      this.$emit('discard-line-oa-user')
      this.$emit('update:shall-show-line-oa-user-modal', false)
    },
    moveOptionsToSelect(direction) {
      let getMovableObjects
      if (direction === 'LTR') {
        getMovableObjects = this.inActiveOptions.filter(elm => this.inActiveSelected.includes(elm.value))

        this.inActiveOptions = this.inActiveOptions.filter(
          elm => !this.inActiveSelected.includes(elm.value),
        )
        this.inActiveSelected = []

        this.activeOptions.push(...getMovableObjects)
      } else if (direction === 'RTL') {
        getMovableObjects = this.activeOptions.filter(elm => this.activeSelected.includes(elm.value))

        this.activeOptions = this.activeOptions.filter(
          elm => !this.activeSelected.includes(elm.value),
        )
        this.activeSelected = []

        this.inActiveOptions.push(...getMovableObjects)
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
